import React from 'react';

import { LandingPageWrapper } from 'components/global/LandingPageWrapper';
import { SEO, SEO_CORE_CONTENT } from 'components/global/SEO';
import { Nav } from 'components/global/Nav';
import { Footer } from 'components/global/Footer';
import {
  LandingHeaderReskin,
  LANDING_HEADER_RESKIN_HOME_CONTENT,
} from 'components/modules/LandingHeaderReskin';
import { FooterDisclaimer } from 'components/global/FooterDisclaimer';

import { Head } from './components/Head';

interface HomepagePropsType {
  isMarketingSafe?: boolean;
}

export const HomepageReskin = ({
  isMarketingSafe = false,
}: HomepagePropsType) => (
  <LandingPageWrapper>
    <Head />
    <SEO {...SEO_CORE_CONTENT} />
    <Nav isMarketingSafe={isMarketingSafe} />
    <LandingHeaderReskin {...LANDING_HEADER_RESKIN_HOME_CONTENT} />
    <Footer
      isMarketingSafe={isMarketingSafe}
      disclaimer={<FooterDisclaimer />}
    />
  </LandingPageWrapper>
);
