import React from 'react';

import { LandingPageWrapper } from 'components/global/LandingPageWrapper';
import { SEO, SEO_CORE_CONTENT } from 'components/global/SEO';
import { Nav } from 'components/global/Nav';
import { Footer } from 'components/global/Footer';
import { FooterDisclaimer } from 'components/global/FooterDisclaimer';
import {
  LandingHeaderSqueeze,
  LANDING_HEADER_SQUEEZE_CONTENT,
} from 'components/modules/LandingHeaderSqueeze';
import {
  WhatWeTreat,
  WHAT_WE_TREAT_CONTENT,
} from 'components/modules/WhatWeTreat';
import { SqueezeRedesignProducts } from 'components/modules/SqueezeRedesignProducts';
import {
  HowItWorks,
  HOW_IT_WORKS_CONTENT,
} from 'components/modules/HowItWorks';
import { Head } from './components/Head';

import { POP_REDESIGN_BANNER_CONTENT } from 'components/global/Nav/components';

interface HomepagePropsType {
  isMarketingSafe?: boolean;
  includeConvert?: boolean;
}

export const HomepageSqueeze = ({
  isMarketingSafe = false,
}: HomepagePropsType) => {
  return (
    <LandingPageWrapper>
      <Head />
      <SEO {...SEO_CORE_CONTENT} />
      <Nav
        isMarketingSafe={isMarketingSafe}
        bannerProps={[POP_REDESIGN_BANNER_CONTENT]}
      />
      <LandingHeaderSqueeze {...LANDING_HEADER_SQUEEZE_CONTENT} />
      <WhatWeTreat {...WHAT_WE_TREAT_CONTENT} />
      <SqueezeRedesignProducts />
      <HowItWorks {...HOW_IT_WORKS_CONTENT} />
      <Footer
        isMarketingSafe={isMarketingSafe}
        disclaimer={<FooterDisclaimer />}
      />
    </LandingPageWrapper>
  );
};
